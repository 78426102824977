/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import ametodologia from "assets/images/a-metodologia.png";

function AMetodologia() {
  return (
    <MKBox
      component="section"
      fontColor="#fff"
      bgColor="linear-gradient(180deg, #11366D 0%, #12305B 100%)"
      sx={{ py: { xs: 10, lg: 20 } }}
      mx={-2}
      id="metodologia"
    >
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={"center"}
            sx={{ pr: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
          >
            <MKBox position="relative" display="flex" justifyContent="center">
              <MKBox
                component="img"
                src={ametodologia}
                alt="A Metodologia"
                maxWidth="400px"
                width="100%"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKTypography mb={1} color="light" variant="h4" fontColor="#fffff">
              A Metodologia
            </MKTypography>
            <MKTypography variant="body2" color="light" mb={3}>
              A metodologia utilizada na Collab é pautada no Método Comunicativo criado pelo
              linguista Britânico Jeremy Harmer, método esse utilizado nas melhores escolas de
              Inglês do Brasil e do mundo. A metodologia comunicativa é extensivamente aplicada na
              Cambridge University - UK na qualificação de professores de Inglês para adultos.
              (CELTA-5). A metodologia Comunicativa é focada nas competências de falar e ouvir
              fazendo com que o aprendizado seja mais rápido e significativo.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AMetodologia;
