/*
=========================================================
* Collab English - v1
=========================================================
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import React from "react";

function Footer({ content }) {
  const { brand, copyright } = content;
  const element = document.getElementById("myElement");
  if (element) {
    element.scrollIntoView();
  }

  return (
    <MKBox component="footer">
      <Container>
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <MKBox>
            <Link to={brand.route}>
              <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="8rem" mb={2} />
            </Link>
          </MKBox>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
          {copyright}
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default Footer;
