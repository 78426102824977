/**
=========================================================
* Collab English - v1
=========================================================
*/

// Collab English Base Styles
import borders from "assets/theme/base/borders";

// Collab English Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.xl,
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },

    media: {
      width: "auto",
    },
  },
};
