// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";

// Collab English components
import MKBox from "components/MKBox";
import React, { useState, useEffect } from "react";

// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

// Collab English components

// import topImage from "assets/images/pexels-andrea-piacquadio-4860600-edit.jpg";
// import Logo from "assets/images/logo-collab-english.svg";
// import LogoWhite from "assets/images/logo-collab-english-white.svg";
// import light from "react-syntax-highlighter/dist/cjs/light";
import Hero from "../../Hero";
import MainNavbar from "../Navbars/MainNavbar";
import routes from "routes";

function Header() {
  const element = document.getElementById("myElement");
  if (element) {
    element.scrollIntoView();
  }

  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      setIsNavbarTransparent(scrollPosition === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <MainNavbar
        routes={routes}
        transparent={isNavbarTransparent}
        light={isNavbarTransparent}
        action={{
          type: "external",
          route: "https://wa.me//5511994852137?text=Tenho%20interesse%20em%20aprender%20ingles",
          label: "WhatsApp",
          color: "whatsapp",
        }}
        sticky
      />
      <MKBox component="header" position="relative">
        <Hero />
      </MKBox>
    </>
  );
}

export default Header;
