/**
=========================================================
* Collab English - v1
=========================================================
*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
