/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";

// Collab English components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Collab English examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import { Parallax } from "react-parallax";

// Images
import team1 from "assets/images/junior-profile.jpg";
import backgroundImage from "assets/images/bg-team.jpg";

function Team() {
  return (
    <Parallax
      bgImage={backgroundImage}
      bgImageSizes={"cover"}
      blur={5}
      style={{ marginRight: "-16px", marginLeft: "-16px", backgroundColor: "#000" }}
      strength={300}
    >
      <MKBox component="section" id="tutor" position="relative" py={20} px={{ xs: 2, lg: 0 }}>
        <Container>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={team1}
              name="Junior Rebello"
              position={{ color: "info", label: "English Tutor" }}
              description={`Junior ensina Inglês há mais de 14 anos, em 2019 iniciou o projeto Collab English
                  que visa criar uma ponte direta entre aluno e professor, com um sistema de ensino de
                  qualidade, totalmente inovador e individualizado, com material preparado
                  exclusivamente para cada aluno, focado nas habilidades de Speaking e Listening. A
                  certificação é constante e obedece aos parâmetros exigidos pela União Europeia.
                  Junior Atua como professor nos estágios Beginners (A1 CEFR) até o Advanced (C1
                  CEFR), além de ser examinador oficial para certificações internacionais.
                  
                  Junior possui vasto conhecimento na preparação de alunos para os exames de Cambridge FCE e
                  CAE. “Mr J”, como é conhecido por seus alunos, possui uma vasta experiência
                  internacional, morou por 5 anos em Adelaide no Estado da Australia do Sul onde
                  possui importantes laços familiares. Se formou em Hospitality pela Regency TAFE
                  School - South Australia. Toda a sua formação técnica foi moldada nos padrões
                  internacionais, onde conquistou o certificado de ensino da língua Inglesa para
                  Adultos (CELTA-5), como também o certificado de proficiência na língua Inglesa CPE
                  C2, sendo esse o nível mais alto de classificação utilizada pela União Europeia
                  (CEFR), ambos os certificados expedidos pela Cambridge University UK. Seu maior
                  sonho é morar em um Veleiro e poder levar sua vivência para lugares nunca dantes
                  navegados. Que tal embarcar nessa travessia?`}
            />
          </MKBox>
        </Container>
      </MKBox>
    </Parallax>
  );
}

export default Team;
