/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Avatar from "@mui/material/Avatar";

// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKTypography from "components/MKTypography";

// Collab English examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import Slider from "react-slick";

// Images
import imgTestmonial1 from "assets/images/testimonial/shirley-emilio.jpg";
import imgTestmonial2 from "assets/images/testimonial/nathan-martins.jpg";
import imgTestmonial3 from "assets/images/testimonial/aline-bernardi-vaz.jpg";
import imgTestmonial4 from "assets/images/testimonial/gabrielli-carli.jpg";
// import imgTestmonial5 from "assets/images/testimonial/5.jpeg";
// import imgTestmonial6 from "assets/images/testimonial/6.jpeg";
// import { Icon } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

function Aspas() {
  return (
    <MKBox
      position="absolute"
      fontSize="3em"
      mt="-1.5rem"
      ml="-2.5rem"
      color="rgba(52, 71, 103, 0.1)"
    >
      <FormatQuoteIcon />
    </MKBox>
  );
}

function Testimonials() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: false,
    draggable: true,
    speed: 1000,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <MKBox
      bgColor={"light"}
      component="section"
      position="relative"
      py={4}
      mx={-2}
      px={{ xs: 0, lg: 0 }}
    >
      <Slider {...settings}>
        <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Shirley Salles Emilio" src={imgTestmonial1} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Shirley Salles Emilio
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              Tenho aulas com uma didática incrível e pela primeira vez gosto de aprender inglês.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Nathan Martins" src={imgTestmonial2} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Nathan Martins
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              O Junior sempre foi muito amigável e disposto a ensinar da forma mais didática
              possível. Seu apoio foi extremamente importante para mim em um período de adaptação
              fora do Brasil.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Aline Bernardi Vaz" src={imgTestmonial3} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Aline Bernardi Vaz
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              Com sua metodologia de ensino leve e focada mantém todos os alunos engajados e
              motivados, enquanto ele habilmente direciona o foco para os conceitos-chave. O
              Professor Júnior possui um talento especial para transmitir conhecimento de forma
              clara e acessível, seu compromisso é em garantir que todos os alunos alcancem seu
              pleno potencial. Ele sabe como maximizar o tempo de sala de aula para garantir que
              cada aluno absorva o máximo de conhecimento possível. Júnior é um mentor ideal para
              quem busca uma educação de qualidade e uma experiência de aprendizado transformadora.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Gabrielli Carli" src={imgTestmonial4} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Gabrielli Carli
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              As aulas de inglês está sendo experiência transformadora para mim. Desde o primeiro
              dia, percebi que o método de ensino era dinâmico. O Júnior sempre incentivando a
              participação ativa e um ambiente acolhedor onde todos se sentem à vontade para
              praticar e cometer erros, o que é essencial para o aprendizado. Graças a essas aulas,
              melhorei significativamente minhas habilidades, mesmo sendo pouco tempo é gratificante
              conseguir entender algumas conversas e ler pequenos textos!
            </MKTypography>
          </MKBox>
        </MKBox>
        {/* <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Nathan Martins" src={imgTestmonial5} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Larisso Machad
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              Estudar online pode ser isolante, mas a Collab English me fez sentir parte de uma
              comunidade solidária. Eu adorei interagir com professores e alunos durante as
              envolventes aulas por vídeo. Minhas habilidades de gramática, vocabulário, leitura,
              escrita, compreensão auditiva e fala melhoraram tremendamente. Estou muito feliz por
              ter escolhido esta escola.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox px={5} flexDirection="column" display="flex">
          <MKBox mb={1} alignItens="center" display="flex">
            <Avatar display="flex" alt="Nathan Martins" src={imgTestmonial6} />
            <MKTypography alignContent={"center"} ml={1} variant="h5" color="text">
              Renata Aragão
            </MKTypography>
          </MKBox>
          <Aspas />
          <MKBox display="flex">
            <MKTypography variant="body2" color="text" mb={3} lineHeight={1.2}>
              Eu estava começando do zero com o inglês, mas os professores pacientes e encorajadores
              da Collab English me deram a base que eu precisava para progredir rapidamente. A
              abordagem de ensino passo a passo deles me permitiu aprender vocabulário e entender
              conceitos gramaticais facilmente.
            </MKTypography>
          </MKBox>
        </MKBox> */}
      </Slider>
    </MKBox>
  );
}

export default Testimonials;
