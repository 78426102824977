/**
=========================================================
* Collab English - v1
=========================================================
*/

export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
