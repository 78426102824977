/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import collabcambridge from "assets/images/collab-cambridge.png";
import collabescola from "assets/images/collab-escola.png";
import collabempresa from "assets/images/collab-empresas.png";
import collabprof from "assets/images/collab-professores.png";

function Collab() {
  return (
    <MKBox
      component="section"
      fontColor="#ffffff"
      bgColor="linear-gradient(180deg, #11366D 0%, #12305B 100%)"
      sx={{ py: { xs: 10, lg: 10 } }}
      mx={-2}
      position="relative"
      id="collab"
    >
      <Container>
        <Grid justifyContent="center" alignItems="center">
          <Grid container mb={6} spacing={10} alignItems="center">
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={"center"}
              sx={{ pr: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
            >
              <MKBox position="relative" display="flex" justifyContent="center">
                <MKBox
                  component="img"
                  src={collabcambridge}
                  alt="Collab/Cambridge UK"
                  sx={{ maxWidth: { xs: "300px", md: "370px" } }}
                  width="100%"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <MKBox width="100%" p={4} borderRadius="15px" bgColor={"#ffffff"}>
                <MKTypography mb={1} variant="h4">
                  Collab/Cambridge UK
                </MKTypography>
                <MKTypography variant="body2" mb={3}>
                  Os Certificados de Cambridge são aceitos mundialmente para ingresso em
                  universidades, processos de imigração e incremento de currículo. Na Collab
                  preparamos você para atingir o seu objetivo. Nossa equipe é composta de
                  examinadores oficiais para certificação internacional. Clique aqui para mais
                  informações.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ flexDirection: { xs: "column", md: "row-reverse" } }}
            direction="column"
            display="flex"
            mb={6}
            spacing={10}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={"center"}
              sx={{ pl: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
            >
              <MKBox position="relative" display="flex" justifyContent="center">
                <MKBox
                  component="img"
                  src={collabescola}
                  alt="Collab na Escola"
                  sx={{ maxWidth: { xs: "300px", md: "370px" } }}
                  width="100%"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <MKBox width="100%" p={4} borderRadius="15px" bgColor={"#ffffff"}>
                <MKTypography mb={1} variant="h4">
                  Collab na Escola
                </MKTypography>
                <MKTypography variant="body2" mb={3}>
                  Reforço escolar e a avaliação do Enem estão presentes no dia a dia das famílias
                  que levam a sério os estudos dos seus filhos e filhas. Aqui na Collab iremos dar
                  todo o suporte necessário para otimizar o desempenho escolar dos adolescentes .
                  Aulas dinâmicas, em grupo, que capturam a atenção dos alunos do início ao fim.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
          <Grid container mb={6} spacing={10} alignItems="center">
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={"center"}
              sx={{ pr: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
            >
              <MKBox position="relative" display="flex" justifyContent="center">
                <MKBox
                  component="img"
                  src={collabempresa}
                  alt="Collab na Empresa"
                  sx={{ maxWidth: { xs: "300px", md: "370px" } }}
                  width="100%"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <MKBox width="100%" p={4} borderRadius="15px" bgColor={"#ffffff"}>
                <MKTypography mb={1} variant="h4">
                  Collab na Empresa
                </MKTypography>
                <MKTypography variant="body2">
                  Você pode levar a Collab para dentro da sua empresa. No Brasil, principalmente no
                  ramo do turismo, que envolve, Hotéis, pousadas, restaurantes e lojas, o inglês
                  ainda é uma barreira para entregar o seu serviço ou produto de forma que cause uma
                  experiência marcante no seu cliente, experiencia essa que pode atravessar
                  continentes. Aqui na Collab preparamos um curso instrumental exclusivo para o seu
                  ramo de atuação. O material é preparado em parceria com o Designer Nathan (link
                  com seu portfolio) exclusivo para o seu negócio, mantendo o layout e indenidade da
                  sua marca.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ flexDirection: { xs: "column", md: "row-reverse" } }}
            spacing={10}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={"center"}
              sx={{ pl: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
            >
              <MKBox position="relative" display="flex" justifyContent="center">
                <MKBox
                  component="img"
                  src={collabprof}
                  alt="Collab para Professores"
                  sx={{ maxWidth: { xs: "300px", md: "370px" } }}
                  width="100%"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <MKBox width="100%" p={4} borderRadius="15px" bgColor={"#ffffff"}>
                <MKTypography mb={1} variant="h4">
                  Collab para Professores
                </MKTypography>
                <MKTypography variant="body2">
                  A Collab pode lhe ajudar a iniciar o seu projeto. Inaugurada em 2019 a Collab é
                  uma empresa que cresce organicamente, foram muitos acertos e erros até chegar a um
                  modelo de negócio sustentável onde o profissional possui uma conexão direta com o
                  aluno, sem intermediários, desta forma o profissional é melhor remunerado pelo
                  serviço que entrega e oferece um produto a um preço acessível para o cliente. O
                  curso Collab para professores engloba a formatação do conteúdo, preparação do
                  material de apoio, passando pelas técnicas de ensino de Inglês on-line e capitação
                  de alunos. Abriremos todas as planilhas, você terá uma noção real do quanto você
                  pode lucrar. O curso é ministrado em parceria com o Designer Nathan, especialista
                  em Marketing digital. Dividido em módulos independentes que podem ser cursados a
                  medida que você expande o seu curso on-line.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Collab;
