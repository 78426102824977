/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import ocurso from "assets/images/o-curso.jpg";
import MKButton from "components/MKButton";

function Ocurso() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MKBox
      component="section"
      id="ocurso"
      mx={-2}
      sx={{ pt: { xs: 10, lg: 20 }, pb: { xs: 55, md: 10, lg: 20 } }}
    >
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} md={6} zIndex={1}>
            <MKTypography variant="h4">O Curso</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              O curso completo da Collab (do iniciante ao Domínio completo da língua) tem uma
              duração média de três(03) anos. O material e o certificado estão incluídos. Não existe
              contrato de fidelidade, você inicia quando quiser e interrompe o curso quando achar
              que deve, sem taxas ou multa de cancelamento, também não existe taxa de matrícula. O
              curso na Collab segue a quadro Europeu Comum de Referência para Línguas (CEFR), que é
              um padrão internacionalmente reconhecido para descrever a proficiência em um idioma.
              Ele divide o conhecimento dos alunos em seis categorias, que inicia no A1(iniciante) e
              termina no C2(Domínio completo).
            </MKTypography>
            <MKButton
              color="dark"
              bgColor="linear-gradient(195deg, #1cb5e0, #000851)"
              onClick={handleOpen}
            >
              Conteúdo Completo
            </MKButton>
          </Grid>
          <MKBox
            xs={12}
            md={6}
            sx={{
              mb: { xs: "-53em", md: "-22em", lg: "-19em" },
              right: { xs: "15px", lg: "2em" },
              "@media (max-width: 600px)": {
                width: "90%",
              },
            }}
            height="auto"
            width="auto"
            zIndex={0}
            position="absolute"
            display="flex"
            justifyContent="center"
          >
            <MKBox
              component="img"
              src={ocurso}
              alt="As Aulas"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: "570px", // Set the default width
              }}
            />
          </MKBox>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 6,
            borderRadius: 4,
            width: "100%",
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflowY: "auto",
            scrollbarColor: "#90A4AE #CFD8DC",
          }}
        >
          <MKBox
            sx={{ position: "absolute", right: 0, top: 0 }}
            bgcolor="#000"
            // borderRadius="50%"
            p={1}
            color="#000"
          >
            <CloseIcon onClick={handleClose} />
          </MKBox>
          <MKBox display="flex" justifyContent="space-between">
            <MKTypography variant="h4" mb="10px">
              Conteúdo do Curso
            </MKTypography>
            <MKButton color="dark" onClick={handleOpen} startIcon={<DownloadIcon />}>
              Download PDF
            </MKButton>
          </MKBox>

          <MKTypography variant="body2" color="text" mb={3}>
            <ol>
              <li>
                <b>A — Básico:</b>
                <ul>
                  <li>
                    A1 (Iniciante): É capaz de compreender e usar expressões familiares e
                    cotidianas, assim como enunciados muito simples, que visam satisfazer
                    necessidades concretas. Pode apresentar-se e apresentar outros e é capaz de
                    fazer perguntas e dar respostas sobre aspectos pessoais como, por exemplo, o
                    local onde vive, as pessoas que conhece e as coisas que tem. Pode comunicar de
                    modo simples, se o interlocutor falar lenta e distintamente e se mostrar
                    cooperante.
                  </li>
                  <li>
                    A2 (Básico): É capaz de compreender frases isoladas e expressões frequentes
                    relacionadas com áreas de prioridade imediata (p. ex.: informações pessoais e
                    familiares simples, compras, meio em que vive). É capaz de comunicar em tarefas
                    simples e em rotinas que exigem apenas uma troca de informação simples e direta
                    sobre assuntos que lhe são familiares e habituais. Pode descrever de modo
                    simples a sua formação, o meio em que vive e, ainda, referir assuntos
                    relacionados com necessidades imediatas.
                  </li>
                </ul>
              </li>
              <li>
                <b>B — Independente:</b>
                <ul>
                  <li>
                    B1 (Intermediário): É capaz de compreender as questões principais, quando é
                    usada uma linguagem clara e estandardizada e os assuntos lhe são familiares
                    (temas abordados no trabalho, na escola e nos momentos de lazer, etc.). É capaz
                    de lidar com a maioria das situações encontradas na região onde se fala o
                    Inglês. É capaz de produzir um discurso simples e coerente sobre assuntos que
                    lhe são familiares ou de interesse pessoal. Pode descrever experiências e
                    eventos, sonhos, esperanças e ambições, bem como expor brevemente razões e
                    justificações para uma opinião ou um projeto.
                  </li>
                  <li>
                    B2 (Usuário Independente): É capaz de compreender as ideias principais sobre
                    assuntos concretos e abstratos, incluindo discussões técnicas na sua área de
                    especialidade. É capaz de comunicar com certo grau de espontaneidade com
                    falantes nativos, sem que haja tensão de parte a parte. É capaz de exprimir-se
                    de modo claro e pormenorizado sobre uma grande variedade de temas e explicar um
                    ponto de vista sobre um tema da atualidade, expondo as vantagens e os
                    inconvenientes de várias possibilidades.
                  </li>
                </ul>
              </li>
              <li>
                <b>C — Proficiente:</b>
                <ul>
                  <li>
                    C1 (Proficiência operativa eficaz): É capaz de compreender um vasto número de
                    situações, reconhecendo os seus significados implícitos. É capaz de se exprimir
                    de forma fluente e espontânea sem precisar procurar muito as palavras. É capaz
                    de usar a língua de modo flexível e eficaz para fins sociais, acadêmicos e
                    profissionais. Pode exprimir-se sobre temas complexos, de forma clara e bem
                    estruturada, manifestando o domínio de mecanismos de organização, de articulação
                    e de coesão do discurso.
                  </li>
                  <li>
                    C2 (Domínio): É capaz de compreender com facilidade praticamente tudo o que
                    ouve. Pode resumir informações de várias fontes faladas, reconstruindo
                    argumentos e relatos de forma coerente. Pode expressar-se espontaneamente, com
                    grande fluência e precisão, diferenciando nuances de significado mesmo em
                    situações mais complexas.
                  </li>
                </ul>
              </li>
            </ol>
          </MKTypography>
        </Box>
      </Modal>
    </MKBox>
  );
}

export default Ocurso;
