/**
=========================================================
* Collab English - v1
=========================================================
*/

// Collab English helper functions
import pxToRem from "assets/theme/functions/pxToRem";

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};
