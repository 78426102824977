/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Parallax } from "react-parallax";

// Images
import bgImage from "assets/images/pexels-andrea-piacquadio-4860600.jpg";
import MKButton from "components/MKButton";
import ballon1 from "assets/images/hero/balloon.svg";
import { motion } from "framer-motion";

function Hero() {
  const handleScroll = () => {
    const contactSection = document.querySelector("#contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Parallax bgImage={bgImage} bgImageStyle={{ top: -150 }} strength={200} position="absolute">
      <MKBox display="flex" alignItems="center" minHeight="100vh">
        <Container>
          <Grid container item xs={12} md={12} lg={12} flexDirection="row" justifyContent="left">
            <MKBox display="flex" flexDirection="row" alignItems="center">
              <MKBox>
                <MKTypography
                  variant="h1"
                  color="white"
                  mb={3}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Let&apos;s Sail Through!
                </MKTypography>
                <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
                  Aprenda Inglês de qualquer lugar em um ambiente colaborativo
                </MKTypography>
                <Stack direction="row" spacing={1} mt={3}>
                  <MKButton color="white" onClick={handleScroll}>
                    Quero aprender Inglês!
                  </MKButton>
                </Stack>
              </MKBox>

              <MKBox
                position="absolute"
                display={{ xs: "none", md: "none", lg: "flex" }}
                justifyContent="center"
                alignItems="center"
                width="100%"
                top={-25}
                right={-90}
                zIndex={1}
              >
                <motion.div
                  animate={{ y: [0, -10, 0] }}
                  transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
                  width="100%"
                >
                  <img src={ballon1} alt="English Ballon" width={"85"} height={"85"} />
                </motion.div>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </Parallax>
  );
}

export default Hero;
