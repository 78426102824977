/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import orcamento from "assets/images/orcamento.jpg";
import MKButton from "components/MKButton";
import { WhatsApp } from "@mui/icons-material";

function Orcamento() {
  return (
    <MKBox
      component="section"
      id="orcamento"
      bgColor="white"
      mx={-2}
      sx={{ py: { xs: 10, lg: 20 } }}
    >
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={"center"}
            sx={{ pr: { xs: 0, lg: 5 }, mb: { xs: 0, md: 0 } }}
          >
            <MKBox position="relative" display="flex" justifyContent="center">
              <MKBox
                component="img"
                src={orcamento}
                alt="Orçamento"
                maxWidth="400px"
                width="100%"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKTypography mb={1} variant="h4" fontColor="#fffff">
              Orçamento
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              O primeiro passo é entrar em contato com o Tutor para agendar um teste de nivelamento.
              É um encontro rápido, on-line, onde será apresentado os valores para aulas individuais
              e em grupo, os horários e dias disponíveis. Clique aqui para agendar o seu teste.
            </MKTypography>
            <MKButton
              color="whatsapp"
              href="https://wa.me//5511994852137?text=Tenho%20interesse%20em%20aprender%20ingles"
              startIcon={<WhatsApp />}
              sx={{ boxShadow: "-4px 4px 0 rgba(57, 152, 93, 1)" }}
            >
              Orçamento Rápido via Whatsapp
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Orcamento;
