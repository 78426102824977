/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import asaulas from "assets/images/as-aulas.jpg";

function Asaulas() {
  return (
    <MKBox component="section" sx={{ py: { xs: 10, lg: 20 } }} mx={-2} id="asaulas">
      <Container>
        <Grid
          container
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          spacing={10}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <MKTypography variant="h4">As Aulas</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              As aulas são ao vivo pela plataforma Zoom meetings e podem ser em grupo ou
              individuais. Cada aula tem uma duração de no mínimo 75 minutos. As aulas em grupo tem
              um limite máximo de 04 alunos. O material é preparado exclusivamente para cada aluno
              ou grupo, levando em consideração a profissão, gostos, experiências de vida e assuntos
              diversos do dia a dia, o conteúdo é transmitido de uma forma leve e divertida. Você
              pode direcionar o foco do aprendizado, Inglês geral, para negócios ou acadêmico. Na
              Collab o aprendizado não é artificial, mas inserido na vida real do aluno, fazendo com
              que a comunicação seja a mais significativa possível.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ pl: { xs: 0, lg: 3 } }}>
            <MKBox position="relative" display="flex" justifyContent="center">
              <MKBox component="img" src={asaulas} alt="As Aulas" maxWidth="450px" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Asaulas;
