/**
=========================================================
* Collab English - v1
=========================================================
*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
