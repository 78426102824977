/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Card from "@mui/material/Card";

// Collab English components
import MKBox from "components/MKBox";

// Collab English components 2
import Header from "./sections/components/header";
import Footer from "./sections/components/footer";

// About Us page sections
import Information from "pages/Home/sections/Information";
import Team from "pages/Home/sections/Team";
import Asaulas from "pages/Home/sections/Asaulas";
import AMetodologia from "pages/Home/sections/Ametodologia";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Testimonials from "./sections/Testimonials";
import Contact from "./sections/Contact";
import Ocurso from "./sections/Ocurso";
import Orcamento from "./sections/Orcamento";
import Collab from "./sections/Collab";

function Home() {
  return (
    <>
      <Header
        routes={routes}
        action={{
          type: "external",
          route: "/#contact",
          label: "Contato",
          color: "default",
        }}
        transparent
        light
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Testimonials />
        <Team />
        <Asaulas />
        <AMetodologia />
        <Ocurso />
        <Collab />
        <Orcamento />
        <Contact />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
