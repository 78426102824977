/**
=========================================================
* Collab English - v1
=========================================================
*/

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
