/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";

// Collab English examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import ImageCard from "assets/images/macbook-1.png";

function Information() {
  return (
    <MKBox component="section" py={12} id="aboutus">
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Inglês Geral"
                    description="Melhore suas habilidades em inglês do dia-a-dia para o trabalho, viagens e situações sociais."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="business"
                    title="Inglês na Empresa"
                    description="Aprenda terminologia de negócios, correspondência, apresentações e mais."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="school"
                    title="Inglês Acadêmico"
                    description="Desenvolva habilidades de inglês para estudos universitários e pesquisa acadêmica."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Preparação para Cambrige"
                    description="Prepare-se para os principais exames de proficiência em inglês com instrução direcionada."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={ImageCard}
              title="Aprenda Inglês de qualquer lugar!"
              description="Aulas particulares ou em grupo com professores experientes. Aprenda inglês de forma fácil e divertida!"
              action={{
                type: "internal",
                route: "#asaulas",
                color: "info",
                label: "Descubra Mais",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
