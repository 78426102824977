/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Collab English - v1
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Collab English components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Mailchimp from "react-mailchimp-form";

import "./styles/contato.css";

function MailchimpForm() {
  return (
    <Mailchimp
      action="https://collabenglish.us18.list-manage.com/subscribe/post?u=bd730d833f4909570a379be6b&amp;id=5db47001a3&amp;f_id=006112e1f0"
      fields={[
        {
          name: "FNAME",
          placeholder: "Nome",
          type: "text",
          required: true,
        },
        {
          name: "EMAIL",
          placeholder: "Email",
          type: "email",
          required: true,
        },
        {
          name: "MENSAGEM",
          placeholder: "Mensagem",
          type: "text",
          required: false,
        },
      ]}
      messages={{
        sending: "Enviando...",
        success: "Obrigado por entrar em contato! Entraremos em contato logo que possível.",
        error: "Algum erro foi encontrado.",
        empty: "Os campos obrigatórios devem ser preenchidos",
        duplicado: "Muitas tentativas de inscrição para este endereço de e-mail",
        button: "Enviar Mensagem",
      }}
      className="form-contato-site"
    />
  );
}

function Contact() {
  return (
    <MKBox
      id="contact"
      component="section"
      py={10}
      mx={-2}
      mb={-2}
      bgColor={"linear-gradient(180deg, #f5f5f5, #f5f5f5)"}
      position="relative"
      borderRadius="0 0 15px 15px"
    >
      <Container>
        <Grid container alignItems="center" justifyContent={"center"}>
          <Grid item textAlign={"center"} xs={12} md={12}>
            <MKBox mb="60px">
              <MKTypography mb="10px" variant="h1">
                Quer Aprender Inglês? Fale Conosco!
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={3}>
                Nosso método exclusivo de ensino foca em conversação real para que você fale inglês
                com confiança e fluência.
              </MKTypography>
            </MKBox>
            <MKBox>
              <MailchimpForm />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
